<template>
  <div class="sponsors-page">
    <h2 class="title">SPONSOR</h2>
    <section class="page-content">
      <div class="flash">
        <div class="flash-row large">
          <Marquee :rowData="firstRow" :number="1"></Marquee>
          <!-- <span :key="`first${i}`" v-for="(item, i) in firstRow" class="item">
            <img v-if="item.coverImage" :src="item.coverImage" />
            <span v-else>{{ item.name }}</span>
          </span> -->
        </div>
        <div class="flash-row large">
          <Marquee :rowData="secondRow" :number="2"></Marquee>
          <!-- <span :key="`second${i}`" v-for="(item, i) in secondRow" class="item">
            <img v-if="item.coverImage" :src="item.coverImage" />
            <span v-else>{{ item.name }}</span>
          </span> -->
        </div>
        <div class="flash-row middle">
          <Marquee :rowData="thirdRow" :number="3"></Marquee>
          <!-- <span :key="`third${i}`" v-for="(item, i) in thirdRow" class="item">
            <img v-if="item.coverImage" :src="item.coverImage" />
            <span v-else>{{ item.name }}</span>
          </span> -->
        </div>
        <div class="flash-row small">
          <Marquee :rowData="fourthRow" :number="4"></Marquee>
          <!-- <span :key="`fourth${i}`" v-for="(item, i) in fourthRow" class="item">
            <img v-if="item.coverImage" :src="item.coverImage" />
            <span v-else>{{ item.name }}</span>
          </span> -->
        </div>
        <div class="flash-row small">
          <Marquee :rowData="fifthRow" :number="5"></Marquee>
          <!-- <span :key="`fifth${i}`" v-for="(item, i) in fifthRow" class="item">
            <img v-if="item.coverImage" :src="item.coverImage" />
            <span v-else>{{ item.name }}</span>
          </span> -->
        </div>
        <div class="flash-row small">
          <Marquee :rowData="sixthRow" :number="6"></Marquee>
          <!-- <span :key="`sixth${i}`" v-for="(item, i) in sixthRow" class="item">
            <img v-if="item.coverImage" :src="item.coverImage" />
            <span v-else>{{ item.name }}</span>
          </span> -->
        </div>
      </div>
      <div class="special-thanks">
        <p class="content-title">
          <span>SPECIAL THANKS</span>
          <span>特別感謝</span>
        </p>
        <div class="items">
          <span
            :key="`sponsor-link-${i}`"
            v-for="(item, i) in specialThanksSponsors"
            class="item"
            :href="item.url || '##'"
          >
            <a :key="`sponsor-link-${i}`" v-if="item.url" :href="item.url">
              {{ item.name }}
            </a>
            <span v-else>{{ item.name }}</span>
          </span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Marquee from "@/components/molecules/Marquee";

export default {
  name: "Sponsors",
  components: { Marquee },
  computed: {
    ...mapState(["sponsors"]),
    specialThanksSponsors() {
      return this.sponsors.filter((s) => s.is_special_thanks);
    },
    firstRow() {
      return this.sponsors.slice(0, 3);
    },
    secondRow() {
      return this.sponsors.slice(3, 6);
    },
    thirdRow() {
      return this.sponsors.slice(7, 13);
    },
    fourthRow() {
      return this.sponsors.slice(13, 19);
    },
    fifthRow() {
      return this.sponsors.slice(19, 25);
    },
    sixthRow() {
      return this.sponsors.slice(25, 32);
    },
  }
};
</script>

<style lang="scss" scoped>
.sponsors-page {
  @include page;

  .title {
    @include page-title($black);
  }

  .page-content {
    display: flex;
    flex-direction: column;
    margin-top: 100px;

    @include RWD($tablet) {
      margin-top: 40px;
    }
  }
}

.flash {
  width: 100%;
  padding-left: 80px;
  margin-bottom: 160px;
  overflow: hidden;

  @include RWD($tablet) {
    padding-left: 12px;
    margin-bottom: 60px;
  }

  .flash-row {
    display: flex;
    color: $black;

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 20vw;
      height: 20vw * 9 / 16;
      padding: 10px;
      box-sizing: border-box;
      border: 1px solid #c4c4c4;

      @include RWD($tablet) {
        flex: 0 0 30vw;
        height: 30vw * 9 / 16;
      }

      & > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      & > span {
        color: $black;
        font-family: "Zpix";
        font-size: 30px;
        text-decoration: none;
        white-space: nowrap;
        letter-spacing: 2px;

        @include RWD($tablet) {
          font-size: 18px;
        }
      }

      & + .item {
        margin-left: 8vw;
      }
    }

    &:not(:last-child) {
      margin-bottom: 150px;

      @include RWD($tablet) {
        margin-bottom: 20px;
      }
    }
  }

  .flash-row.middle {
    .item {
      flex: 0 0 16vw;
      height: 16vw * 9 / 16;

      & + .item {
        margin-left: 6vw;
      }

      @include RWD($tablet) {
        flex: 0 0 24vw;
        height: 24vw * 9 / 16;
      }

      & > span {
        font-size: 26px;
        @include RWD($tablet) {
          font-size: 16px;
        }
      }
    }
  }

  .flash-row.small {
    .item {
      flex: 0 0 13vw;
      height: 13vw * 9 / 16;

      @include RWD($tablet) {
        flex: 0 0 18vw;
        height: 18vw * 9 / 16;
      }

      & + .item {
        margin-left: 4vw;
      }

      & > span {
        font-size: 22px;
        @include RWD($tablet) {
          font-size: 12px;
        }
      }
    }
  }
}

.special-thanks {
  margin: 0 270px;

  @include RWD($tablet) {
    margin: 0 12px;
  }

  .content-title {
    display: flex;
    align-items: center;
    color: $primary-blue;
    margin-bottom: 40px;

    @include RWD($mediumLaptop) {
      margin-bottom: 15px;
    }

    & > span {
      &:first-child {
        font-family: "Red Rose";
        font-size: 35px;
        font-weight: 400;
        letter-spacing: 0.04em;
        margin-right: 45px;

        @include RWD($mediumLaptop) {
          font-size: 29px;
          line-height: 44px;
        }

        @include RWD($tablet) {
          font-size: 18px;
          line-height: 18px;
          margin-right: 20px;
        }
      }

      &:last-child {
        font-family: "Zpix";
        font-size: 22px;
        font-weight: 500;

        @include RWD($mediumLaptop) {
          font-size: 16px;
          line-height: 44px;
        }

        @include RWD($tablet) {
          font-size: 12px;
          line-height: 12px;
        }
      }
    }
  }

  .items {
    display: flex;
    flex-wrap: wrap;

    .item {
      margin-bottom: 20px;
      font-size: 0;

      @include RWD($mediumLaptop) {
        margin-bottom: 10px;
      }

      a,
      span {
        color: $black;
        font-family: "Zpix";
        font-size: 22px;
        text-decoration: none;
        white-space: nowrap;
        letter-spacing: 1px;
        padding-bottom: 5px;
        border-bottom: 1px solid transparent;

        @include RWD($mediumLaptop) {
          font-size: 16px;
          line-height: 44px;
        }

        @include RWD($tablet) {
          font-size: 12px;
          line-height: 12px;
          transform-origin: left;
          transform: scale(10/12);
        }
      }

      a {
        cursor: pointer;

        &:hover {
          border-bottom: 1px solid $primary-blue;
        }
      }

      &:not(:last-child) {
        margin-right: 50px;

        @include RWD($tablet) {
          margin-right: 24px;
        }
      }
    }
  }
}
</style>

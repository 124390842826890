<template>
  <section class="marquee" ref="marque"
    :style="`animation: ${240 + number*10}s linear infinite marquee${number};`"
  >
    <span :key="`first${i}`" v-for="(item, i) in rowData" class="item">
      <img v-if="item.coverImage" :src="item.coverImage" />
      <span v-else>{{ item.name }}</span>
    </span>
    <span :key="`first${i}`" v-for="(item, i) in rowData" class="item">
      <img v-if="item.coverImage" :src="item.coverImage" />
      <span v-else>{{ item.name }}</span>
    </span>
    <span :key="`first${i}`" v-for="(item, i) in rowData" class="item">
      <img v-if="item.coverImage" :src="item.coverImage" />
      <span v-else>{{ item.name }}</span>
    </span>
    <span :key="`first${i}`" v-for="(item, i) in rowData" class="item">
      <img v-if="item.coverImage" :src="item.coverImage" />
      <span v-else>{{ item.name }}</span>
    </span>
    <span :key="`first${i}`" v-for="(item, i) in rowData" class="item">
      <img v-if="item.coverImage" :src="item.coverImage" />
      <span v-else>{{ item.name }}</span>
    </span>
    <div v-html="animation"></div>
  </section>
</template>

<script>
export default {
  name: "Marquee",
  components: {  },
  props: {
    rowData: Array,
    number: Number
  },
  setup(props) {
    return {
    };
  },
  mounted(){
    const animation = `
    <style>
      @keyframes marquee${this.number} {
        
        0%{
          transform: translateX(0%);
          opacity: 1
        }

        49%{
          opacity: 1
        }
        50%{
          transform: translateX(-120%);
          opacity: 0
        }

        51%{
          transform: translateX(100%);
          opacity: 0
        }

        52%{
          opacity: 1
        }
        100%{
          transform: translateX(0%);
        }
      }
    </style>
    `
    this.animation = animation
  },
  data(){
    return {
      animation: null
    }
  }
};
</script>

<style lang="scss" scoped>
.flash {
  width: 100%;
  padding-left: 80px;
  margin-bottom: 160px;
  overflow: hidden;
  .marquee{
    transform: translateX(0);
  }

  @include RWD($tablet) {
    padding-left: 12px;
    margin-bottom: 60px;
  }

  .flash-row {
    display: flex;
    color: $black;

    .marquee{
      display: flex;
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 20vw;
      height: 20vw * 9 / 16;
      padding: 10px;
      box-sizing: border-box;
      border: 1px solid #c4c4c4;

      @include RWD($tablet) {
        flex: 0 0 30vw;
        height: 30vw * 9 / 16;
      }

      & > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      & > span {
        color: $black;
        font-family: "Zpix";
        font-size: 30px;
        text-decoration: none;
        white-space: nowrap;
        letter-spacing: 2px;

        @include RWD($tablet) {
          font-size: 18px;
        }
      }

      & + .item {
        margin-left: 8vw;
      }
    }

    &:not(:last-child) {
      margin-bottom: 150px;

      @include RWD($tablet) {
        margin-bottom: 20px;
      }
    }
  }

  .flash-row.middle {
    .item {
      flex: 0 0 16vw;
      height: 16vw * 9 / 16;

      & + .item {
        margin-left: 6vw;
      }

      @include RWD($tablet) {
        flex: 0 0 24vw;
        height: 24vw * 9 / 16;
      }

      & > span {
        font-size: 26px;
        @include RWD($tablet) {
          font-size: 16px;
        }
      }
    }
  }

  .flash-row.small {
    .item {
      flex: 0 0 13vw;
      height: 13vw * 9 / 16;

      @include RWD($tablet) {
        flex: 0 0 18vw;
        height: 18vw * 9 / 16;
      }

      & + .item {
        margin-left: 4vw;
      }

      & > span {
        font-size: 22px;
        @include RWD($tablet) {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
